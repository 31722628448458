import { styled } from "../../styles";
import { Heading } from "../Typography/Typography";
import React, { PropsWithChildren } from "react";

const BaseModalHeader = styled(Heading, {
  margin: "0",
});

export const ModalHeader = ({ children }: PropsWithChildren<unknown>) => {
  return <BaseModalHeader type="h6">{children}</BaseModalHeader>;
};

export const sharedModalStyles = {
  content: {
    borderRadius: "4px",
    padding: "$3",
  },

  overlay: {
    backgroundColor: "rgba(0, 0, 0, 0.75)",
    zIndex: "$eAbsolute",
  },
};
