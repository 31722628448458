import { ElementType, useEffect, useState } from "react";
import { GridItem } from "../Layout/GridItem";
import Button from "../Button/Button";
import { GridContainer } from "../Layout/GridContainer";
import { styled } from "../../styles";
import { DashboardCard } from "../DashboardCard/DashboardCard";
import { useRouter } from "next/router";

export type BaseTabItem = {
  title: string;
  visible?: boolean;
};

export type ContentTabItem = BaseTabItem & {
  content: ElementType;
};

export type LinkTabItem = BaseTabItem & {
  href: string;
};

export type TabItem = ContentTabItem | LinkTabItem;

export const TabTitle = styled(Button, {
  opacity: 0.5,

  variants: {
    isCurrentTab: {
      true: {
        opacity: 1,
      },
    },
  },
});

export type TabObject = {
  [key: string]: TabItem;
};

export interface TabProps {
  items: TabObject;
  borderless?: boolean;
  showTabTitle?: boolean;
  disableRouting?: boolean;
}

export const Tab = ({
  items,
  showTabTitle = true,
  borderless = false,
  disableRouting = false,
}: TabProps) => {
  const router = useRouter();
  const currentTabFromUrl = router.query.tab as string;

  const tabItems = Object.keys(items)
    .map((key) => ({
      ...items[key],
      key,
    }))
    .filter((item) => ("visible" in item ? item.visible : true));

  const firstTabItem = tabItems && tabItems[0];

  const defaultTabKey =
    currentTabFromUrl && currentTabFromUrl in items
      ? currentTabFromUrl
      : firstTabItem?.key;

  const [currentTabKey, setCurrentTabKey] = useState<string>(defaultTabKey);

  const activeTab = tabItems.find((item) => item.key === currentTabKey);

  const ActiveTabContent =
    activeTab && "content" in activeTab && activeTab.content;

  const handleTabSwitch = (key: string, item: TabItem) => {
    if ("href" in item) {
      router.push(item.href);
      return;
    }

    setCurrentTabKey(key);

    if (disableRouting) return;

    // TODO: Fix shallow routing of tabs
    // const queryParams = router.query || {};
    // delete queryParams.activeStore;

    // router.push(
    //   {
    //     pathname: injectStoreIntoLink(router.pathname),
    //     query: {
    //       ...queryParams,
    //       tab: key,
    //     },
    //   },
    //   undefined,
    //   {
    //     shallow: true,
    //   },
    // );
  };

  // Set the active tab if there is none set or the items are dynamically updated
  useEffect(() => {
    if (!activeTab || !(activeTab.key in items)) {
      setCurrentTabKey(firstTabItem?.key);
    }
  }, [items]);

  // Set the current tab on first load
  useEffect(() => {
    if (currentTabFromUrl && currentTabFromUrl in items) {
      setCurrentTabKey(currentTabFromUrl);
    }
  }, []);

  if (!activeTab) {
    return null;
  }

  return (
    <GridContainer
      css={{
        marginBottom: "$4",
      }}
    >
      <GridItem
        css={{
          overflowX: "auto",
          whiteSpace: "nowrap",
        }}
      >
        {tabItems.map((item) => {
          const isCurrentTab = item.key === activeTab.key;

          return (
            <TabTitle
              size="small"
              isCurrentTab={isCurrentTab}
              inline
              type="button"
              onClick={() => handleTabSwitch(item.key, item)}
              design="textOnly"
              key={item.key}
            >
              {item.title}
            </TabTitle>
          );
        })}
      </GridItem>

      {ActiveTabContent && (
        <GridItem>
          <DashboardCard
            borderless={borderless}
            title={showTabTitle ? activeTab.title : undefined}
          >
            <ActiveTabContent />
          </DashboardCard>
        </GridItem>
      )}
    </GridContainer>
  );
};
