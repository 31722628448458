import React from "react";
import { GridContainer } from "../Layout/GridContainer";
import { styled } from "../../styles";
import { GridItem } from "../Layout/GridItem";
import { Caption } from "../Typography/Typography";

const Container = styled(GridContainer, {
  backgroundColor: "$accent2Shade4",
  borderRadius: "4px",
  padding: "$2 $1",
});

export type FormErrorType = object | string[] | object[] | undefined;

export type FormErrorsProps = {
  errors: FormErrorType;
};

export type ErrorMessageObj = {
  [key: string]: string | ErrorMessageObj | Array<ErrorMessageObj>;
};

/**
 * Convert underscored field names to friendly names
 * @param txt
 */
const friendlyFieldName = (txt: string) =>
  txt
    .replace(/_id$/, "")
    .replace(/_/g, " ")
    .replace(/^\w/, (c) => c.toUpperCase());

const handleErrMsg = (
  msg: string | ErrorMessageObj | Array<ErrorMessageObj>,
): string => {
  if (Array.isArray(msg)) {
    return msg.join(". ");
  }

  if (typeof msg === "object") {
    const errMsg = Object.keys(msg).map((key) => {
      const val = msg[key];

      return `${friendlyFieldName(key)}: ${handleErrMsg(val)}`;
    });

    return errMsg.join(". ");
  }

  return msg;
};

export const parseAPIErrors = (baseErrors: FormErrorType): string[] => {
  if (typeof baseErrors === "object" && "__all__" in baseErrors) {
    return Array.isArray(baseErrors["__all__"])
      ? baseErrors["__all__"]
      : [baseErrors["__all__"]];
  }

  if (!Array.isArray(baseErrors) || baseErrors.length === 0) return [];

  const errors = baseErrors.map((err) => {
    if (typeof err === "string") {
      return err;
    }

    if (typeof err === "object" && "message" in err && "field" in err) {
      const errField = Array.isArray(err.field)
        ? err.field.join(" ")
        : err.field;

      return `${friendlyFieldName(errField as string)}: ${handleErrMsg(err.message as ErrorMessageObj)}`;
    }

    if (typeof err === "object") {
      return Object.values(err).join(". ");
    }

    return err;
  });

  return errors;
};

export const FormErrors = ({ errors: baseErrors }: FormErrorsProps) => {
  const parsedErrors = parseAPIErrors(baseErrors);

  return (
    Array.isArray(parsedErrors) &&
    parsedErrors.length > 0 && (
      <Container>
        {parsedErrors.map((error) => {
          return (
            <GridItem key={error}>
              <Caption
                css={{
                  color: "$white",
                }}
              >
                {error}
              </Caption>
            </GridItem>
          );
        })}
      </Container>
    )
  );
};
