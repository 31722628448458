import { DashboardCard } from "./DashboardCard";
import { GridContainer } from "../Layout/GridContainer";
import { GridItem } from "../Layout/GridItem";
import { Caption } from "../Typography/Typography";
import { styled } from "../../styles";
import Link from "next/link";

export type PerfItem = {
  label: string;
  value: string;
  href: string | null;
};

export interface PerfCardProps {
  title: string;
  items: PerfItem[];
}

const PerfCardTitle = styled(Caption, {
  color: "$darkShade5",
  display: "block",
  fontSize: "12px",
  fontWeight: "500",
  lineHeight: "16px",
  wordWrap: "no-wrap",
  textOverflow: "ellipsis",
  overflowX: "hidden",
});

type PerfCardLinkProps = {
  href?: string;
};

export const PerfCard = ({ items, title }: PerfCardProps) => {
  if (!items || items.length === 0) return null;

  return (
    <DashboardCard
      title={title}
      styleAttributes={{
        minHeight: "unset",

        "@bp2": {
          maxHeight: "420px",
          overflowY: "auto",
        },
      }}
    >
      <GridContainer css={{ marginTop: "$2" }}>
        {Array.isArray(items) &&
          items.map((item) => {
            const LinkComp = item.href ? Link : "div";

            const linkProps: PerfCardLinkProps = {};

            if (item.href) {
              linkProps.href = item.href;
            }

            return (
              <GridItem key={item.label} title={item.label}>
                <LinkComp {...(linkProps as any)}>
                  <GridContainer>
                    <GridItem
                      desktop={9}
                      css={{
                        whiteSpace: "noWrap",
                      }}
                    >
                      <PerfCardTitle title={item.label} ellipsised>
                        {item.label}
                      </PerfCardTitle>
                    </GridItem>

                    <GridItem
                      desktop={3}
                      css={{
                        textTransform: "capitalize",
                      }}
                    >
                      {item.value}
                    </GridItem>
                  </GridContainer>
                </LinkComp>
              </GridItem>
            );
          })}
      </GridContainer>
    </DashboardCard>
  );
};
